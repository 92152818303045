'use client';
import { Provider } from 'react-redux';
import { store } from '@lib/redux/store';
import { useEffect } from 'react';
import { Provider as RollbarProvider, ErrorBoundary, useRollbar } from '@rollbar/react';
import { useInteractionGuard } from '@interaction-guard/index';

const rollbarConfig =
  process.env['NODE_ENV'] !== 'development'
    ? {
        accessToken: process.env['NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN'],
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env['NEXT_PUBLIC_ENVIRONMENT'],
          client: {
            javascript: {
              source_map_enabled: true,
              code_version: '1.0.0',
              guess_uncaught_frames: true,
            },
          },
        },
      }
    : {};

export function Providers({ children }: React.PropsWithChildren<{}>) {
  const { isIntercepted, pendingFn, clearInterception } = useInteractionGuard();
  useEffect(() => {
    if (isIntercepted) {
      if (pendingFn) {
        pendingFn.fn(...pendingFn.args);
        clearInterception();
      }
    }
  }, [isIntercepted, pendingFn, clearInterception]);

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Provider store={store}>{children}</Provider>
      </ErrorBoundary>
    </RollbarProvider>
  );
}

export function useLogErrorToRollbar(errorDetails) {
  const rollbar = useRollbar();
  useEffect(() => {
    if (errorDetails) {
      rollbar.error('Error Page Rendered', {
        ...errorDetails,
        stackTrace: errorDetails.error?.stack,
        componentStack: errorDetails.error?.componentStack,
      });
    }
  }, [errorDetails, rollbar]);
}
